<template>
  <div
      v-loading.fullscreen.lock="fullscreenLoading">
    <div class="container">
      <div class="box left">
        <img class="mask" :style="{ opacity: opacity / 100 }"
             :src="maskCom?maskCom:null"
             alt=""/>
        <img class="source"
             :src="initPic.pic?initPic.pic:null"
             alt=""/>
      </div>
      <div class="right">
        <div class="box top">
          <el-slider
              v-model="opacity"
              :min="0"
              :max="100"
              :show-tooltip="false"
              show-input>
          </el-slider>
        </div>
        <div class="box mid">
          <div style="position: absolute;width: 100%">
            <el-table
                v-loading="load"
                ref="map"
                id="maskTable"
                :data="mask"
                border
                highlight-current-row
                @row-click="handleRowClick"
                style="width: 100%">
              <el-table-column
                  prop="name"
                  label="Mask"
                  align="center"
                  width="70">
              </el-table-column>
              <el-table-column
                  label="莫则菲"
                  align="center"
                  show-overflow-tooltip>
                <template slot-scope="{ row }">
                  <el-tag
                      effect="dark"
                      @dblclick.native="deleteTag(row)"
                      style="user-select: none">
                    {{ row.mlabel }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                  :label="useridNameAcc.username"
                  show-overflow-tooltip
                  align="center">
                <template slot-scope="{ row }">
                  <el-tag
                      :type="row.status==='wrong'?'danger':''"
                      effect="dark">
                    {{ row.ulabel }}
                  </el-tag>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="box bottom" v-if="submitVisible">
          <div style="display: flex;justify-content: center;align-items: center;">
            <el-button @click="exportToJSON" type="success">Submit</el-button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-dialog
          title="请选择要替换的标签"
          :visible.sync="dialogLabelVisible">
        <el-button
            v-for="(button,index) in labelChange.labels"
            :key="index"
            type="primary"
            plain
            @click="labelClick(button)"
            style="margin-left: 10px;margin-bottom: 20px;margin-right: 10px;font-weight: bold">
          {{ button.label }}
        </el-button>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {config} from "dotenv";

export default {
  name: "accDetail",
  data() {
    return {
      submitVisible: false,//提交按钮的显示开关
      dialogLabelVisible: false,//更换标签的开关
      user: JSON.parse(window.sessionStorage.getItem('user')),
      useridNameAcc: null,
      valImgId: null,//验证图片id
      fullscreenLoading: false,//全屏加载
      load: false,
      opacity: 50,//mask的透明度
      mask: null,
      maskCom: null,//mask的src
      initPic: {
        pic: null,
        valImgId: null,
        initImgAddress: null,
        maskAddress: null
      },//验证图
      currentRow: null,//当前选中的行（标签）
      labelChange: {
        labels: null
      }
    }
  },
  methods: {
    deleteTag(row) {
      this.dialogLabelVisible = true;
      // console.log(row)
    },
    handleRowClick(row, event) {
      this.currentRow = row;
      this.maskCom = row.src;
    },
    //整页加载
    openFullScreen() {
      this.fullscreenLoading = true;
    },
    // 获取原始图片与mask图片的base64与渲染
    async getPicAndMaskBase64() {
      try {
        this.fullscreenLoading = true;
        const res = await this.getRequest(`/mzfCheck/getValImgById/${this.valImgId}`);
        this.initPic.valImgId = res.obj.id;
        this.initPic.initImgAddress = res.obj.initialImageAddress;
        this.initPic.maskAddress = res.obj.maskImageAddress;

        const re = await axios.get('/img/getImgAndMaskByPath', {
          params: {
            'path': this.initPic.initImgAddress,
            'maskPath': this.initPic.maskAddress
          }
        });
        this.initPic.pic = 'data:img/jpeg;base64,' + re.initImgAddress;
        const mask = re.maskArray;

        const r = await axios.get('/mzfCheck/getMaskMlabelUlabelByValImgId', {
          params: {
            'mUserId': this.user.id,
            'uUserId': this.useridNameAcc.userid,
            'valImgId': this.valImgId
          }
        });
        for (let i = 0; i < mask.length; i++) {
          mask[i].mlabel = r.obj[i].mlabel;
          mask[i].ulabel = r.obj[i].ulabel;
          if (r.obj[i].mlabel !== r.obj[i].ulabel) {
            mask[i].status = 'wrong';
          }
        }
        this.getRequest('/label/getAllLabel').then(res => {
          this.labelChange.labels = res;
          // console.log(this.labels)
        })
        this.mask = mask;
        this.fullscreenLoading = false;
      } catch (error) {
        console.error('发生错误：', error);
      }
    },
    exportToJSON() {
      // 整页加载
      this.fullscreenLoading = true;
      let len = this.mask.length;
      // 输出打了标签的数量
      let count = 0;
      for (let i = 0; i < len; i++) {
        if (this.mask[i]) {
          count++;
        }
      }
      // console.log(this.mask)
      //如果count和len的长度一样，发送请求（标签打完了）
      if (count === len) {
        this.$confirm('Are you sure to submit?', 'Warning', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          const userid = this.user.id;
          const prefix = this.maskPrefix;
          let submitMask = this.mask.map(item => ({
            name: item.name,
            path: item.path.replace(prefix, ''),
            tag: item.mlabel
          }))
          // console.log(submitMask)
          // this.postRequest(`/mzfToJsonFile/export/${userid}/${this.imgid}`, submitMask).then(() => {
          this.postRequest(`/mzfToJsonFile/jsonChange/${userid}/${this.valImgId}`, submitMask).then(() => {
            this.fullscreenLoading = false;
            //   询问是否刷新网页
            this.$confirm('Successfully submitted. Do you want to return to the previous page or stay on this page?', 'Success', {
              confirmButtonText: 'Back',
              cancelButtonText: 'Stay',
              type: 'success'
            }).then(() => {
              this.$router.go(-1);
            }).catch(() => {
              this.submitVisible = false;
            });
          }).catch(() => {
            this.$message({
              type: 'info',
              message: 'Submission failed, please contact the administrator'
            })
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Canceled'
          })
          this.fullscreenLoading = false;
        }).finally(() => {
          this.fullscreenLoading = false;
        });
      } else {
        this.$message.error('Please type all the labels before submitting');
        this.fullscreenLoading = false;
      }
    },
    labelClick(button) {
      this.currentRow.mlabel = button.label;
      if (this.submitVisible === false)
        this.submitVisible = true
    }
  },
  created() {
    this.useridNameAcc = this.$route.query.userIdNameAcc;
    this.valImgId = this.$route.query.valImgId;
    this.getPicAndMaskBase64();
  },
  computed: {}
}
</script>


<style scoped>
.mask {
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  object-fit: cover;
  z-index: 2;
}

.source {
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  object-fit: cover;
  z-index: 1;
}

.container {
  display: flex;
  height: 86vh;
}

.left {
  position: relative;
  margin-right: 5px;
  padding: 5px;
  flex: 0 0 70%;
  background-color: #99a9bf;
}

.right {
  align-items: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.top {
  padding: 5px;
  flex: 0.1;
}

.mid {
  padding: 5px;
  flex: 1.5;
  position: relative;
  background-color: #d3dce6;
}

.box {
  border-radius: 10px;
  overflow: auto;
}

.bottom {
  padding: 5px;
  flex: 0.1;
}
</style>