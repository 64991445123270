<template>
  <div>
    <!--展示验证图片列表-->
    <el-table
        :data="imgs"
        v-loading="loading"
        border
        stripe
        style="width: 100%">
      <el-table-column
          prop="id"
          label="序号"
          width="80"
          align="center">
      </el-table-column>
      <el-table-column
          prop="initialImageAddress"
          label="图片"
          align="center">
      </el-table-column>
      <el-table-column
          prop="maskImageAddress"
          label="Mask"
          align="center">
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          width="150"
      >
        <template slot-scope="scope">
          <el-button type="primary" @click="handleClick(scope.row)" size="small">查看统计信息</el-button>
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--          prop="isprocessed"-->
      <!--          label="isProcessed"-->
      <!--          width="150">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-tag v-if="scope.row.isprocessed" type="success" effect="dark">Processed</el-tag>-->
      <!--          <el-tag v-else type="danger" effect="dark">Untreated</el-tag>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>
    <el-pagination
        style="margin-top: 20px;"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper,->"
        :page-sizes="[10, 30, 50, 70, 90, 100]"
        :total="totalImgs">
    </el-pagination>

    <el-drawer
        :title="drawer.title"
        :visible.sync="drawer.status"
        :direction="drawer.direction"
        @close="drawerClose">
      <div class="drawer-table">
        <el-table
            empty-text="用户或管理员还未勾画"
            :data="drawer.tableData"
            style="width: 100%">
          <el-table-column
              prop="userid"
              label="用户id"
              v-if="false">
          </el-table-column>
          <el-table-column
              prop="username"
              label="姓名"
              align="center">
          </el-table-column>
          <el-table-column
              prop="acc"
              label="匹配率"
              align="center">
            <template slot-scope="scope">
              {{ formatNumber(scope.row.acc) }}%
            </template>
          </el-table-column>
          <el-table-column
              label="详情"
              align="center">
            <template slot-scope="scope">
              <el-button size="small" type="primary" @click="toAccDetail(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "acc",
  data() {
    return {
      //获取用户信息
      user: JSON.parse(window.sessionStorage.getItem('user')),
      //抽屉信息
      drawer: {
        title: "准确度",
        status: false,
        direction: "rtl",
        tableData: null,
      },
      //图片数组
      imgs: [],
      //分页参数
      currentPage:
          1,
      pageSize:
          10,
      totalImgs:
          0,
      loading:
          false,
      //详情查看的验证图片id
      valImgId: null
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getValImgList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getValImgList();
    },
    // 获取验证图片列表
    getValImgList() {
      this.loading = true;
      axios.get('/mzfCheck/getValImgByPage', {
        params: {
          page: this.currentPage,
          size: this.pageSize
        }
      }).then(res => {
        this.imgs = res.records;
        this.totalImgs = res.total;
        this.loading = false;
      })
    },
    // 验证图片表格右侧的操作按钮点击事件
    handleClick(row) {
      this.drawer.status = true;
      this.drawer.title = row.id + "/" + row.initialImageAddress + "/勾画情况";
      this.valImgId = row.id;
      axios.get('/mzfCheck/getAccByValImgJsonMask', {
        params: {
          'mUserId': this.user.id,
          'valImgId': row.id
        }
      }).then(res => {
        if (res != null)
          this.drawer.tableData = res.obj;
      })
    },
    drawerClose() {
      this.drawer.tableData = null;
      this.valImgId = null;
    },
    toAccDetail(row) {
      // console.log(row)
      this.$router.push({
        path: '/accDetail',
        query: {
          valImgId: this.valImgId,
          userIdNameAcc: row
        }
      })
    },
    formatNumber(num) {
      return (num * 100).toLocaleString();
    }
  },
  created() {
    this.getValImgList();
  }
}
</script>

<style scoped>
.drawer-table {
  padding: 10px;
}
</style>