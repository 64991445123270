<template>
  <div>
    <el-container style="width: 100%">
      <el-header class="homeHeader">
        <div class="title">Medical machine vision endoscopic image public dataset</div>
        <div>
          <!--          <el-button-->
          <!--              type="text"-->
          <!--              icon="el-icon-bell"-->
          <!--              size="normal"-->
          <!--              style="margin-right:8px;color: black;"-->
          <!--              @click="goView('chat')"></el-button>-->
          <el-dropdown class="userInfo" @command="commandHandler">
          <span class="el-dropdown-link" style="color: white;">
            {{ user.username }}
            <i><img style="margin-top: 10px;"
                    src="https://c-ssl.duitang.com/uploads/blog/202402/27/oVS0eQDmHgWn985.jpeg"
                    alt=""></i>
          </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="userinfo">Personal Center</el-dropdown-item>
              <!--              <el-dropdown-item command="setting">设置</el-dropdown-item>-->
              <el-dropdown-item command="logout">Log out</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-container>
        <el-aside width="10%">
          <el-menu router>
            <!--            <el-submenu>-->
            <!--              <template slot="title">-->
            <!--                <i class="el-icon-menu"></i>-->
            <!--                <span>导航一</span>-->
            <!--              </template>-->
            <!--            <el-menu-item-group>-->
            <!--                <template slot="title">分组一</template>-->
            <el-menu-item index="/home">Home</el-menu-item>
            <el-menu-item v-if="type==='4'" index="/acc">acc-mzf</el-menu-item>
            <el-menu-item index="/MyWork">My Work</el-menu-item>
            <el-menu-item v-if="type==='4'" index="/dailyAcc">dailyAcc</el-menu-item>
            <el-menu-item v-if="type==='4'" index="/moImgSeg">imgSeg-mzf</el-menu-item>
            <!--            <el-menu-item v-if="type==='4'" index="/CheckWork">审核统计</el-menu-item>-->
            <!--            <el-menu-item v-if="type==='4'" index="/ImgCheck">图片审核</el-menu-item>-->
            <!--            <el-menu-item v-if="type==='3'" index="/ReviewerWork">My Work</el-menu-item>-->
            <!--            <el-menu-item v-if="type==='3'" index="/ReviewerViewPage">Review Image Label</el-menu-item>-->
            <el-menu-item v-if="type==='2'" index="/labelSeg">Label division</el-menu-item>
            <!--            <el-menu-item v-if="type==='2'" index="/certificateMan">Certificate</el-menu-item>-->
            <!--            <el-menu-item v-if="type==='1'" index="/AllWork">All Work</el-menu-item>-->
            <!--            <el-menu-item v-if="type==='1'" index="/userMan">User Management</el-menu-item>-->
            <!--            <el-menu-item v-if="type==='1'" index="/labelMan">Label Management</el-menu-item>-->
            <!--            <el-menu-item v-if="type==='1'" index="/imgMan">Image Management</el-menu-item>-->
            <!--                <el-menu-item index="1-2">选项2</el-menu-item>-->
            <!--            </el-menu-item-group>-->
            <!--              <el-menu-item-group title="分组2">-->
            <!--                <el-menu-item index="1-3">选项3</el-menu-item>-->
            <!--              </el-menu-item-group>-->
            <!--            </el-submenu>-->
          </el-menu>
        </el-aside>
        <el-main style="width: 92%">
          <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!=='/home'">
            <el-breadcrumb-item :to="{ path: '/home' }">Home</el-breadcrumb-item>
            <el-breadcrumb-item>{{ this.$router.currentRoute.name }}</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="homeWelcome" v-if="this.$router.currentRoute.path==='/home'">
            Medical machine vision endoscopic image public dataset
            <div>The development team：ZeroStudio</div>
          </div>
          <router-view class="homeRouterView"></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  methods: {
    goChat() {
      this.$router.push('/chat');
    },
    goView(path) {
      this.$router.push('/' + path);
    },
    commandHandler(command) {
      if (command === 'logout') {
        this.$confirm('Do you want to log out?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          //注销登录
          this.postRequest('/logout');
          //清空用户信息
          window.sessionStorage.removeItem('tokenStr');
          window.sessionStorage.removeItem('user');
          //清空vuex的菜单
          this.$store.commit('initRoutes', []);
          //跳转到登录页
          this.$router.replace('/')
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Cancelled login out'
          });
        });
      }
      if (command === 'userinfo') {
        this.$router.push('/userinfo');
      }
    }
  },
  data() {
    return {
      isCollapse: true,
      type: window.sessionStorage.getItem('type'),
      user: JSON.parse(window.sessionStorage.getItem('user'))
    }
  },
  computed: {
    routes() {
      // console.log('这里是home.vue');
      // console.log(this.$store.state.routes);
      // return this.$store.state.routes;
    }
  }
}
</script>

<style scoped>
.homeHeader {
  background: rgba(64, 158, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-sizing: border-box;
}

.homeHeader .title {
  font-size: 30px;
  font-family: 华云楷体;
  color: white;
}

.homeHeader .userInfo {
  cursor: pointer;
}

.el-dropdown-link img {
  width: 52px;
  height: 48px;
  border-radius: 24px;
  margin-left: 8px;
}

.homeWelcome {
  margin-top: 300px;
  text-align: center;
  font-size: 30px;
  font-family: 微软雅黑;
  color: #81b8f1;
  padding-top: 50px;
}

.homeRouterView {
  margin-top: 10px;
}

.el-aside el-menu {
  height: 100%;
  overflow: hidden;
}

.el-aside .el-menu .el-scrollbar__wrap {
  padding-right: 0 !important;
}
</style>