<template>
  <div>
    <div>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button type="primary" icon="el-icon-plus" @click="showAddDialog()">Add</el-button>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 30px">
      <el-table
          :data="labels"
          v-loading="loading"
          border
          stripe
          style="width: 100%">
        <el-table-column
            prop="labelName"
            label="Label name"
            width="180">
        </el-table-column>
        <el-table-column
            label="Operate"
            width="200">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="showEditDialog(scope.row)">Edit</el-button>
            <el-button type="danger" size="mini" @click="doDelete(scope.row)">Delete</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper, ->"
          background
          :page-sizes="[5,10, 20, 30, 40]"
          :total="totalItems">
      </el-pagination>
    </div>
    <el-dialog title="Modify labels" :visible.sync="dialogEditVisible">
      <el-form :model="editForm">
        <el-form-item label="Label name">
          <el-input v-model="editForm.labelName" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogEditVisible = false">Cancel</el-button>
        <el-button type="primary" @click="doEditLabel">Confirm</el-button>
      </div>
    </el-dialog>
    <el-dialog title="Add label" :visible.sync="dialogAddVisible">
      <el-form :model="addForm">
        <el-form-item label="Label name">
          <el-input v-model="addForm.labelName" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogAddVisible = false">Cancel</el-button>
        <el-button type="primary" @click="addLabel">Confirm</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "labelMan",
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      totalItems: 0,
      editForm: {
        labelId: 0,
        labelName: ''
      },
      addForm: {
        labelName: ''
      },
      dialogEditVisible: false,
      dialogAddVisible: false,
      labels: [],
      loading: false
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getItems();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getItems();
    },
    getItems() {
      this.loading = true;
      axios.get('/label2/getLabel2ByPage', {
        params: {
          page: this.currentPage,
          size: this.pageSize
        }
      }).then(response => {
        // console.log(response)
        this.labels = response.records;
        this.totalItems = response.total;
        this.loading = false;
      });
    },
    showEditDialog(row) {
      this.dialogEditVisible = true;
      this.editForm.labelId = row.labelId;
      this.editForm.labelName = row.labelName;
      // console.log(row)
    },
    showAddDialog() {
      this.dialogAddVisible = true;
      this.addForm.labelName = '';
    },
    doDelete(row) {
      // console.log(row)
      //   添加删除
      this.$confirm(`Are you sure to delete "${row.labelName}" label?`, 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.deleteRequest(`/label2/deleteLabel2ById/${row.labelId}`).then(res => {
          if (res.code === 200) {
            this.$message({
              message: res.message,
              type: 'success'
            });
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            });
          }
          this.getLabels();
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    doEditLabel() {
      console.log(this.editForm)
      this.loading = true;
      if (this.editForm.labelName === '') {
        this.$message({
          message: 'Label name cannot be empty',
          type: 'warning'
        });
        return;
      }
      this.putRequest(`/label2/updateLabel2ById/${this.editForm.labelId}`, this.editForm).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: 'success'
          });
        } else {
          this.$message({
            message: res.message,
            type: 'error'
          });
        }
        this.loading = false;
        this.dialogEditVisible = false;
        this.getLabels();
      })
    },
    getLabels() {
      this.loading = true;
      this.getRequest('/label2/getAllLabel2').then(res => {
        this.labels = res;
        this.loading = false;
      })
    },
    addLabel() {
      this.loading = true;
      if (this.addForm.labelName === '') {
        this.$message({
          message: 'Label name cannot be empty',
          type: 'warning'
        });
        this.loading = false;
        this.dialogEditVisible = false;
        return;
      }
      this.postRequest(`/label2/insertLabel2/${this.addForm.labelName}`).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: 'success',
            onClose: () => {
              this.loading = false;
              this.dialogAddVisible = false;
            }
          });

        } else {
          this.$message({
            message: res.message,
            type: 'error',
            onClose: () => {
              this.loading = false;
              this.dialogAddVisible = false;
            }
          });
        }
        this.loading = false;
        this.dialogAddVisible = false;
        this.getLabels();
      }).finally(() => {
        this.loading = false;
        this.dialogAddVisible = false;
      })
    }
  },
  created() {
    this.getItems();
  },
}
</script>
<style scoped>

</style>