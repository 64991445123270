<template>
  <div>
    <!--展示图片列表-->
    <el-table
        :data="imgs"
        v-loading="loading"
        border
        stripe
        style="width: 100%">
      <el-table-column
          prop="id"
          label="id"
          width="80">
      </el-table-column>
      <el-table-column
          prop="initialImageAddress"
          label="Image Address">
      </el-table-column>
      <el-table-column
          prop="maskImageAddress"
          label="Mask Address">
      </el-table-column>
      <el-table-column
          prop="jsonAddress"
          label="JSON Address">
      </el-table-column>
      <el-table-column
          prop="isprocessed"
          label="isProcessed"
          width="150">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isprocessed" type="success" effect="dark">Processed</el-tag>
          <el-tag v-else type="danger" effect="dark">Untreated</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="isprocessing"
          label="isProcessing"
          width="150">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isprocessing" type="danger" effect="dark">Processing</el-tag>
          <el-tag v-else type="success" effect="dark">Unoccupied</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper,->"
        :page-sizes="[10, 20, 30, 40]"
        :total="totalImgs">
    </el-pagination>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "imgMan",
  data() {
    return {
      imgs: [],
      currentPage: 1,
      pageSize: 10,
      totalImgs: 0,
      loading: false,
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getImg();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getImg();
    },
    getImg() {
      this.loading = true;
      axios.get('/img/getImgByPage', {
        params: {
          page: this.currentPage,
          size: this.pageSize
        }
      }).then(res => {
        this.imgs = res.records;
        this.totalImgs = res.total;
        this.loading = false;
      })
    },
  },
  created() {
    this.getImg();
  }
}
</script>

<style scoped>

</style>