<template>
  <div
      v-loading.fullscreen.lock="fullscreenLoading">
    <div class="container">
      <!---->
      <div class="box left">
        <img class="mask" :style="{ opacity: opacity / 100 }"
             :src="maskCom?maskCom:null"
             alt=""/>
        <img class="source"
             :src="initPic?initPic:null"
             alt=""/>
      </div>
      <div class="right">
        <div class="box top">
          <span style="font-size: 15px">Slide the slider to adjust the contrast</span>
          <el-slider
              v-model="opacity"
              :min="0"
              :max="100"
              :show-tooltip="false"
              show-input>
          </el-slider>
        </div>
        <div class="box mid">
          <div style="position: absolute;width: 100%">
            <el-table
                v-loading="load"
                ref="map"
                id="maskTable"
                :data="mask"
                border
                highlight-current-row
                @row-click="handleRowClick"
                style="width: 100%">
              <el-table-column
                  prop="name"
                  label="Mask"
                  align="center"
                  width="70">
              </el-table-column>
              <el-table-column
                  label="Label"
                  align="center"
                  show-overflow-tooltip>
                <template slot-scope="{ row }">
                  <el-tag
                      v-if="row.tag"
                      closable
                      type="primary"
                      effect="dark"
                      @dblclick.native="deleteTag(row)">
                    {{ row.tag }}
                  </el-tag>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="box bottom">
          <el-button
              v-for="(button,index) in labels"
              :key="index"
              type="primary"
              plain
              @click="buttonClick(button)"
              style="margin-left: 10px;margin-bottom: 20px;margin-right: 10px;font-weight: bold">
            {{ button.label }}
          </el-button>
          <br/>
          <div style="display: flex;justify-content: center;align-items: center;">
            <el-button @click="exportToJSON" type="success">Submit</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import _ from 'lodash'

export default {
  name: "imgSeg",
  data() {
    return {
      user: null,
      imgid: null,
      fullscreenLoading: false,//全屏加载
      load: false,
      opacity: 50,//mask透明度调节数据
      mask: [],//存放mask的数组{‘name’:'','src':''}
      maskCom: null,//mask的src
      maskAddress: null,//mask的地址
      initPic: '',//原图
      initImgAddress: '',//原图的地址
      labels: [],//标签数组
      loading: false,//加载
      currentRow: null,//当前行
      imgPrefix: null,//初始图片的前缀
      maskPrefix: null,//mask图片的前缀
    }
  },
  methods: {
    //标签删除的回调函数
    deleteTag(row) {
      this.$set(row, 'tag', null);
    },
    //整页加载
    openFullScreen() {
      this.fullscreenLoading = true;
    },
    //发送后端数据
    exportToJSON: _.throttle(function () {
      {
        // 整页加载
        this.fullscreenLoading = true;
        let len = this.mask.length;
        // 输出打了标签的数量
        let count = 0;
        for (let i = 0; i < len; i++) {
          if (this.mask[i].tag) {
            count++;
          }
        }
        // console.log(this.mask)
        //如果count和len的长度一样，发送请求（标签打完了）
        if (count !== 0 && len !== 0) {
          if (count === len) {
            this.$confirm('Are you sure to submit?', 'Warning', {
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
              type: 'warning'
            }).then(() => {
              const userid = this.user.id;
              const prefix = this.maskPrefix;
              let submitMask = this.mask.map(item => {
                item.path = item.path.replace(prefix, '');
                return item;
              });
              this.postRequest(`/toJsonFile/export/${userid}/${this.imgid}`, submitMask).then(res => {
                this.fullscreenLoading = false;
                //   询问是否刷新网页
                this.$confirm('Successfully submitted. Do you want to refresh the webpage or stop tagging?', 'Success', {
                  confirmButtonText: 'Refresh',
                  cancelButtonText: 'Stop Tagging',
                  type: 'success'
                }).then(() => {
                  window.location.reload();
                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: 'You can exit the webpage now'
                  })
                  this.$router.replace('/home');
                });
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: 'Submission failed, please contact the administrator'
                })
              });
            }).catch((e) => {
              console.log(e)
              this.$message({
                type: 'info',
                message: 'Canceled'
              })
              this.fullscreenLoading = false;
            }).finally(() => {
              this.fullscreenLoading = false;
            });
          } else {
            this.$message.error('Please type all the labels before submitting');
            this.fullscreenLoading = false;
          }
        }
      }
    }, 1000),
    //标签点击事件
    buttonClick(button) {
      let index = -1;
      //如何选中了行
      if (this.currentRow) {
        //并且当前行并没有打标签
        if (!this.currentRow.tag) {
          //获取当前行的索引
          index = this.mask.indexOf(this.currentRow);
          //给当前行打标签
          this.$set(this.currentRow, 'tag', button.label);
          //当前mask的tag打完以后，自动下一行，并且高亮也下一行，并且自动滚动
          if (index < this.mask.length - 1) {
            this.currentRow = this.mask[index + 1];
            this.maskCom = this.mask[index + 1].src;
            //高亮当前行
            const table = this.$refs.map;
            table.setCurrentRow(this.currentRow);
            // 计算滚动条的滚动位置
            const scrollTop = table.$el.querySelector('.el-table__body-wrapper').scrollTop;
            const rowHeight = table.$el.querySelector('.el-table__row').offsetHeight;
            const offset = index * rowHeight - table.$el.querySelector('.el-table__body-wrapper').offsetHeight / 2 + rowHeight / 2;

            // 滚动到指定位置
            table.$el.querySelector('.el-table__body-wrapper').scrollTop = offset;
          }
        } else {
          //如果所有的标签都打完了，显示标签已经打完
          if (index === this.mask.length - 1) {
            this.$message.success('All labels have been typed out');
          } else {
            this.$message.error('The current mask has been labeled. If you want to modify it, please delete it first');
          }
        }
      } else {
        this.$message.error('Please select a mask first');
      }
    },
    //点击行的时候，获取当前行的数据
    handleRowClick(row, event) {
      this.currentRow = row;
      this.maskCom = row.src;
    },
    //获取所有的标签
    getLabels() {
      this.loading = true;
      this.getRequest('/label/getAllLabel').then(res => {
        this.labels = res;
        // console.log(this.labels)
        this.loading = false;
      })
    },
    //随机获取没有处理过以及没有正在处理的img
    getOneImgNotProcessedNotProcessing() {
      const userid = this.user.id;
      this.loading = true;
      this.getRequest(`/img/getOneImg/${userid}`).then(res => {
        // console.log('一张图片的信息',res)
        this.imgid = res.obj.id;
        this.initImgAddress = res.obj.initialImageAddress;
        this.maskAddress = res.obj.maskImageAddress;
        //获取原图以mask的base
        axios.get('/img/getImgAndMaskByPath', {
          params: {
            'path': this.initImgAddress,
            'maskPath': this.maskAddress
          }
        }).then(res => {
          // console.log('所有mask',res)
          this.initPic = 'data:image/jpeg;base64,' + res.initImgAddress;
          this.mask = res.maskArray;
          this.imgPrefix = res.imgPrefix;
          this.maskPrefix = res.maskPrefix;
          this.loading = false;
          this.fullscreenLoading = false;
        });
      })
    },
    getUserInfo() {
      this.user = JSON.parse(window.sessionStorage.getItem('user'));
    }
  },
  created() {
    this.getUserInfo();
    this.openFullScreen();
    this.getLabels();
    this.getOneImgNotProcessedNotProcessing();
  },
}
</script>

<style scoped>
.mask {
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  object-fit: contain;
  z-index: 2;
}

.source {
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  object-fit: contain;
  z-index: 1;
}

.container {
  display: flex;
  height: 86vh;
}

.left {
  position: relative;
  margin-right: 5px;
  padding: 5px;
  flex: 0 0 70%;
}

.right {
  align-items: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.top {
  margin-bottom: 5px;
  padding: 5px;
  flex: 0.2;
}

.mid {
  margin-bottom: 5px;
  padding: 5px;
  flex: 1;
  position: relative;
  background-color: #d3dce6;
}

.bottom {
  padding: 5px;
  flex: 1;
}

.box {
  border-radius: 10px;
  overflow: auto;
}
</style>
