<template>
  <div>
    <div style="margin: 20px" v-if="hasCertificate">
      <span style="margin-top: 200px;margin-bottom: 20px;">Please upload the certificate</span>
      <el-upload class="upload"
                 ref="upload"
                 action="string"
                 :file-list="fileList"
                 :auto-upload="false"
                 :http-request="uploadFile"
                 :on-change="handleChange"
                 :on-preview="handlePreview"
                 :on-remove="handleRemove"
                 list-type="picture-card"
                 multiple="multiple">
        <el-button slot="trigger"
                   size="small"
                   type="primary"
                   @click="delFile">选取文件
        </el-button>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible" size="tiny">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
      <!--准备一个提交按钮-->
      <el-button type="primary"
                 style="margin-top: 30px;"
                 @click="onSubmit">提交
      </el-button>
    </div>
    <div style="margin: 20px" v-else>
      <!--      <el-dialog :visible.sync="dialogVisible" size="tiny">-->
      <el-image width="50%" :src="certificateUrl" alt=""/>
      <!--      </el-dialog>-->
    </div>
  </div>
</template>
<script>

export default {
  name: "certificateMan",
  data() {
    return {
      dialogVisible: false,
      uploadUrl: '/upload/Certificate',
      user: JSON.parse(sessionStorage.getItem("user")),
      userId: JSON.parse(sessionStorage.getItem("user")).id,
      hasCertificate: JSON.parse(sessionStorage.getItem("user")).certificate === null,
      // el-upload组件绑定的属性—— :file-list=“fileList”,渲染后fileList[index]是Object类型,而不是后台所需的File类型,
      // 而这个组件已经把对应的File类型存储到了fileList[index].raw这个属性里,直接拿来用就好.
      fileList: [],
      // 不支持多选
      multiple: false,
      formData: "",
      certificateUrl: '',
      dialogImageUrl: ''
    };
  },
  methods: {
    //提交证书图片
    onSubmit() {
      let formData = new FormData();
      formData.append("file", this.fileList[0].raw);//拿到存在fileList的文件存放到formData中
      //添加数据到formData(使用键值对方式存储)
      formData.append("id", this.userId);
      this.$confirm('Do you want to submit the certificate?', 'Tip', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.postRequest('/upload/Certificate', formData, {
          "Content-Type": "multipart/form-data;charset=utf-8"
        }).then(res => {
          const user = JSON.parse(sessionStorage.getItem("user"));
          user.certificate = 'done';
          window.sessionStorage.setItem('user', JSON.stringify(user));
          this.$router.go(0);
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Canceled'
        });
      });
    },
    //点击上传文件触发的额外事件,清空fileList
    delFile() {
      // this.fileList = [];
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
      // console.log(this.fileList, "sb");
    },
    //自定义上传文件
    uploadFile(file) {
      this.formData.append("file", file.file);
      // console.log(file.file, "sb2");
    },
    //删除文件
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    // 点击文件
    handlePreview(file) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
      // console.log(file);
    },
    //通过用户id来获取其证书
    getCertificateByUserId(id) {
      this.getRequest(`/user/getCertificateByUserId/${id}`).then(res => {
        this.certificateUrl = 'data:image/jpeg;base64,' + res;
      })
    },
  },
  mounted() {
    this.getCertificateByUserId(this.userId)
  },
}
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>