import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import AdminInfo from "@/views/AdminInfo";
import Home from "@/views/Home";
import FriendChat from "@/views/chat/FriendChat";
import LabelSeg from "@/views/User/imgSeg/imgSeg.vue";
import LabelMan from "@/views/Manager/labelMan/labelMan";
import UserMan from "@/views/Manager/userMan/userMan";
import index from "@/views/index";
import certificateMan from "@/views/Manager/certificateMan/certificateMan";
import imgMan from "@/views/Manager/imgMan/imgMan";
import MyWork from "@/views/User/MyWork/MyWork.vue";
import AllWork from "@/views/Manager/AllWork/AllWork.vue";
// 审核员
import ReviewerViewPage from "@/views/User/ReviewerViewPage/index.vue";
import ReviewerWork from "@/views/User/ReviewerWork/index.vue";
import moImgSeg from "@/views/User/imgSeg/moImgSeg.vue";
import acc from "@/views/User/acc/index.vue";
import accDetail from "@/views/User/acc/accDetail.vue";
import dailyAcc from "@/views/User/acc/dailyAcc.vue";
//方差审核
import checkWork from "@/views/newChecker/checkWork.vue";
import imgCheck from "@/views/newChecker/imgCheck.vue";

Vue.use(VueRouter)


const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        hidden: true
    },
    {
        path: '/',
        name: 'index',
        component: index,
        hidden: true
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        children: [{
            path: '/chat',
            name: 'chat',
            component: FriendChat
        }, {
            path: '/userinfo',
            name: 'user profile',
            component: AdminInfo
        }, {
            path: '/labelSeg',
            name: 'Label division',
            component: LabelSeg
        }, {
            path: '/moImgSeg',
            name: 'moImgSeg',
            component: moImgSeg
        }, {
            path: '/acc',
            name: 'acc',
            component: acc
        }, {
            path: '/accDetail',
            name: 'accDetail',
            component: accDetail
        }, {
            path: '/dailyAcc',
            name: 'dailyAcc',
            component: dailyAcc
        }, {
            path: '/labelMan',
            name: 'Label Management',
            component: LabelMan
        }, {
            path: '/userMan',
            name: 'User Management',
            component: UserMan
        }, {
            path: '/certificateMan',
            name: 'Certificate',
            component: certificateMan
        }, {
            path: '/imgMan',
            name: 'Image Management',
            component: imgMan
        }, {
            path: '/MyWork',
            name: 'My Work',
            component: MyWork
        }, {
            path: '/AllWork',
            name: 'All Work',
            component: AllWork
        }, {
            path: '/ReviewerViewPage',
            name: 'ReviewerViewPage',
            component: ReviewerViewPage
        }, {
            path: '/ReviewerWork',
            name: 'ReviewerWork',
            component: ReviewerWork
        }, {
            path: '/ImgCheck',
            name: 'imgCheck',
            component: imgCheck
        }, {
            path: '/CheckWork',
            name: 'checkWork',
            component: checkWork
        }
        ]

    },
]

const router = new VueRouter({
    // mode: 'history',
    routes
})

export default router
