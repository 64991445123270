<template>
  <div>
    <transition name="flip" mode="out-in">
      <el-form v-loading="loading" v-if="isLogin"
               key="login"
               element-loading-text="Processing......"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               :rules="rules" ref="loginForm" :model="loginForm" class="logincontainer">
        <h2 class="logintitle">Login in</h2>
        <el-form-item prop="username">
          <el-input type="text" v-model="loginForm.username" auto-complete="false"
                    placeholder="Please enter your email address"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" auto-complete="false" v-model="loginForm.password"
                    placeholder="Please enter the password"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input type="text" auto-complete="false" v-model="loginForm.code"
                    placeholder="Click image to change"
                    style="width: 300px;margin-right:5px "></el-input>
          <img :src="captchaUrl" @click="updateCaptcha"/>
        </el-form-item>
        <el-form-item>
          <el-radio v-model="loginForm.type" label="2" value="2">User</el-radio>
<!--          <el-radio v-model="loginForm.type" label="3" value="3">Reviewer</el-radio>-->
          <el-radio v-model="loginForm.type" label="1" value="1">Manager</el-radio>
          <el-radio v-model="loginForm.type" label="4" value="4">Reviewer</el-radio>
        </el-form-item>
        <!--        <label>-->
        <!-- <el-checkbox v-model="checked" class="rememberme">记住我</el-checkbox>-->
        <!--        </label>-->
        <el-form-item>
          <el-button type="success" style="width: 100%" @click.prevent="submitLogin">Login in</el-button>
          <!--          <el-button type="primary" style="width: 100%" @click="changeLoginOrRegister">No account to register-->
          <!--          </el-button>-->
        </el-form-item>
      </el-form>
      <el-form v-loading="loading" v-if="!isLogin"
               key="register"
               element-loading-text="Processing......"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(0, 0, 0, 0.8)"
               @keydown.enter.native=""
               :rules="rules" ref="loginForm" :model="registerForm" class="logincontainer">
        <h2 class="logintitle">Sign up</h2>
        <el-form-item prop="email" required>
          <el-input type="text" v-model="registerForm.email" auto-complete="true"
                    placeholder="Please enter your email address"></el-input>
        </el-form-item>
        <el-form-item prop="username">
          <el-input type="text" v-model="registerForm.username" auto-complete="false"
                    placeholder="Please enter your username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" auto-complete="false" v-model="registerForm.password"
                    placeholder="Please enter your password"></el-input>
        </el-form-item>
        <el-form-item prop="rePassword">
          <el-input type="password" auto-complete="false" v-model="registerForm.rePassword"
                    placeholder="Please enter the password again"></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input type="text" v-model="registerForm.phone" auto-complete="false"
                    placeholder="Please enter your phone number"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" style="width: 100%" @click="submitRegister">Submit</el-button>
          <el-button type="primary" style="width: 100%" @click="changeLoginOrRegister">
            Existing account, log in
          </el-button>
        </el-form-item>
      </el-form>
    </transition>
  </div>
</template>

<script>
// import {postRequest} from "@/utils/api";

export default {
  name: "Login",
  data() {
    return {
      emailRegex: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, // 正则表达式用于验证邮箱格式
      isLogin: true,
      radio: '2',
      images: [
        {path: '../assets/img/1.jpg'},
        {path: '../assets/img/2.jpg'},
        {path: '../assets/img/3.jpg'},
        {path: '../assets/img/4.jpg'},
      ],
      loading: false,
      checked: true,
      captchaUrl: '/captcha?time=' + new Date(),
      loginForm: {
        username: '',
        password: '',
        code: '',
        type: '2'
      },
      registerForm: {
        email: '',
        username: '',
        password: '',
        rePassword: '',
        phone: '',
        active: true,
        type: '2'
      },
      rules: {
        email: [{required: true, message: 'Please enter your email address', trigger: 'blur'}],
        username: [{required: true, message: 'Please enter your username', trigger: 'blur'}],
        password: [{required: true, message: 'Please enter your password', trigger: 'blur'}],
        rePassword: [{required: true, message: 'Please enter the password again', trigger: 'blur'}],
        certificate: [{required: true, message: '请输入证书编号', trigger: 'blur'}],
        phone: [{required: true, message: 'Please enter your phone number', trigger: 'blur'}],
      },
      note: {
        backgroundImage: "url(" + require("../assets/img/3.jpg") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: '100%'
      },
    }
  },
  created() {
    this.updateCaptcha(); // 初始化验证码
  },
  methods: {
    validateEmail() {
      if (!this.emailRegex.test(this.email)) {
        // 邮箱格式无效，提示无效
        this.$message({
          message: 'Invalid email format',
          type: 'error'
        });
      } else {
        // 邮箱格式有效，执行相应逻辑
      }
    },
    submitRegister() {
      this.loading = true;
      //如果两次密码不一致
      if (this.registerForm.password !== this.registerForm.rePassword) {
        this.$message({
          message: 'Entered passwords differ',
          type: 'error'
        });
        this.loading = false;
        return;
      }
      // 判断所有的属性是否为空
      for (let key in this.registerForm) {
        if (this.registerForm[key] === '') {
          this.$message({
            message: 'Please fill in the complete information',
            type: 'error'
          });
          this.loading = false;
          return;
        }
      }
      const requestDate = {
        email: this.registerForm.email,
        username: this.registerForm.username,
        password: this.registerForm.password,
        certificate: this.registerForm.certificate,
        phone: this.registerForm.phone,
        active: this.registerForm.active,
        type: this.registerForm.type
      };
      this.postRequest('/user/register', requestDate).then(resp => {
        if (resp) {
          if (resp.code !== 500) {
            this.$message({
              message: resp.message,
              type: 'success'
            });
            this.changeLoginOrRegister();
            this.registerForm = {
              email: '',
              username: '',
              password: '',
              rePassword: '',
              certificate: '',
              phone: '',
              active: true,
              type: '2'
            }
          } else {
            this.$message({
              message: resp.message,
              type: 'error'
            });
          }
        }
        this.loading = false;
      })
    },
    changeLoginOrRegister() {
      this.isLogin = !this.isLogin;
    },
    updateCaptcha() {
      this.captchaUrl = this.$base + '/captcha?time=' + new Date()
    },
    submitLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          // postRequest()
          this.postRequest('/login', this.loginForm).then(resp => {
            if (resp) {
              // this.loading = false;
              //存储用户token
              const tokenStr = resp.obj.tokenHead + resp.obj.token;
              window.sessionStorage.setItem('tokenStr', tokenStr);
              window.sessionStorage.setItem('type', this.loginForm.type);
              //页面跳转
              let path = this.$route.query.redirect;
              // 跳转 replace使得无法后退
              // this.$router.replace('/home');
              this.$router.replace((path === '/login' || path === undefined) ? '/home' : path);
            } else {
              this.loading = false;
              this.updateCaptcha();
              this.loginForm.code = '';
            }
          })
        } else {
          this.$message.error('Please fill in the complete information');
          return false;
        }
      });
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

.logincontainer {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 120px auto;
  width: 350px;
  padding: 15px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

.logintitle {
  text-align: center;
  margin-bottom: 20px;
}

.rememberme {
  text-align: left;
  margin: 0px 0px 15px 0px;
}

.el-form-item__content {
  display: flex;
  align-content: center;
}

.flip-enter-active,
.flip-leave-active {
  transition: transform 0.6s;
}

.flip-enter,
.flip-leave-to {
  transform: rotateY(91deg);
}
</style>