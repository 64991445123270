<template>
  <div>
    <div style="margin-top: 30px">
      <!--展示用户列表-->
      <el-table
          :data="users"
          v-loading="loading"
          border
          stripe
          style="width: 100%">
        <el-table-column
            prop="id"
            label="id"
            width="90">
        </el-table-column>
        <el-table-column
            prop="email"
            label="Email"
            width="180">
        </el-table-column>
        <el-table-column
            prop="username"
            label="Username"
            width="180">
        </el-table-column>
        <el-table-column
            prop="phone"
            label="PhoneNumber"
            width="180">
        </el-table-column>
        <el-table-column
            prop="certificate"
            label="Certificate"
            width="300">
          <template slot-scope="scope">
            <div v-if="scope.row.certificate==null">
              <el-button type="info" disabled size="mini">None</el-button>
            </div>
            <div v-else>
              <el-button type="success" size="mini" @click="showCertificate(scope.row.id)">View Certificate</el-button>
              <el-dialog :visible.sync="dialogVisible" size="tiny">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="active"
            label="isActive"
            width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.active" type="success" effect="dark">Enable</el-tag>
            <el-tag v-else type="danger" effect="dark">Disable</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="type"
            label="UserType"
            width="100"
            align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type === '1'" type="success" effect="dark">Manager</el-tag>
            <el-tag v-else-if="scope.row.type === '2'" type="warning" effect="dark">User</el-tag>
            <el-tag v-else-if="scope.row.type === '3'" effect="dark">Corrector</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="Operation"
            width="200">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="editUser(scope)">
              Edit User
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          background
          layout="total, sizes, prev, pager, next, jumper,->"
          :page-sizes="[10, 20, 30, 40]"
          :total="totalUsers">
      </el-pagination>
      <el-dialog title="Edit User" :visible.sync="editFormVisible">
        <el-form :model="editUserForm">
          <el-form-item label="Email">
            <el-input v-model="editUserForm.email" autocomplete="off" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="Username">
            <el-input v-model="editUserForm.username" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="PhoneNumber">
            <el-input v-model="editUserForm.phone" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Certificate">
            <el-input v-model="editUserForm.certificate" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="isActive">
            <el-switch v-model="editUserForm.active"
                       active-color="#13ce66"
                       inactive-color="#ff4949"
                       :active-value="true"
                       :inactive-value="false"></el-switch>
          </el-form-item>
          <el-form-item label="User Type">
            <el-radio v-model="editUserForm.type" label="2">勾画员</el-radio>
            <el-radio v-model="editUserForm.type" label="3">审核员</el-radio>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editFormVisible = false">Cancel</el-button>
          <el-button type="primary" @click="doEdit">Confirm</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "userMan",
  data() {
    return {
      userType: 2,
      currentPage: 1,
      pageSize: 10,
      totalUsers: 0,
      dialogVisible: false,
      dialogImageUrl: '',
      editFormVisible: false,
      editUserForm: {
        id: 0,
        username: '',
        email: '',
        phone: '',
        certificate: '',
        active: true,
        type: '',
        password: ''
      },
      users: [],
      loading: false,
      selectedId: null,
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getUsers();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUsers();
    },
    showCertificate(id) {
      this.dialogVisible = true;
      this.getRequest(`/user/getCertificateByUserId/${id}`).then(res => {
        this.dialogImageUrl = 'data:image/jpeg;base64,' + res;
      })
    },
    openEditDialog() {

    },
    closeEditDialog() {

    },
    getUsers() {
      this.loading = true;
      axios.get('/user/getUserByPage', {
        params: {
          page: this.currentPage,
          size: this.pageSize
        }
      }).then(res => {
        this.users = res.records;
        this.totalUsers = res.total;
        this.loading = false;
      })
    },
    editUser(scope) {
      this.editFormVisible = true;
      // console.log(scope);
      this.editUserForm = {
        id: scope.row.id,
        username: scope.row.username,
        email: scope.row.email,
        phone: scope.row.phone,
        certificate: scope.row.certificate,
        active: scope.row.active,
        type: scope.row.type,
        password: scope.row.password
      };
      // console.log(this.editUserForm);
    },
    doEdit() {
      this.loading = true;
      this.putRequest(`/user/updateUserById/${this.editUserForm.id}`, this.editUserForm).then(res => {
        // this.getUsers();
        this.loading = false;
        this.editFormVisible = false;
        this.getUsers()
      });
    },
    deleteUserById(id) {
      this.loading = true;
      this.deleteRequest(`/label/deleteUserById/${id}`).then(res => {
        this.getUsers();
        this.loading = false;
        this.getUsers()
      });
    },
  },
  created() {
    this.getUsers();
  },
  mounted() {
  }
}
</script>
<style scoped>

</style>